import type DataGridControl from 'o365.controls.Grid.DataGrid.ts';
import type BaseColumn from 'o365.controls.Grid.BaseColumn.ts';
import BaseGridHeader from 'o365.controls.Grid.extension.BaseHeader.ts';

declare module 'o365.controls.Grid.DataGrid.ts' {
    interface DataGridControl {
        header: DataGridHeader;
    }
}

export default class DataGridHeader extends BaseGridHeader<DataGridControl> {


    handleHeaderClick(e: MouseEvent, col: BaseColumn, sortDirection?: 'asc'|'desc') {

        if (col.sortable) {
            e.preventDefault();
            const gridControl = this._getGridControl();
            const dataObject = gridControl.dataObject;

            const currentField = dataObject.fields.find(field => field.name === col.field);
            let vCurrentSortOrder = dataObject.recordSource.getSortOrder();
            let vSortOrder = {};
            if (!vCurrentSortOrder) vCurrentSortOrder = [];

            switch (currentField.sortDirection) {
                case 'asc':
                    currentField.sortDirection = 'desc';
                    break;
                case 'desc':
                default:
                    currentField.sortDirection = 'asc';
            }

            if (sortDirection) {
                currentField.sortDirection = sortDirection;
            }
            vSortOrder[col.field] = currentField.sortDirection;
            if (!e.ctrlKey) {
                vCurrentSortOrder = [vSortOrder];
            } else {
                if (col.sort) {
                    vCurrentSortOrder.forEach((item, index) => {
                        const vTmp = Object.keys(item);
                        if (vTmp[0] === col.field) {
                            vCurrentSortOrder[index] = vSortOrder;
                        }
                    });
                } else {
                    vCurrentSortOrder.push(vSortOrder);
                }

            }

            dataObject.recordSource.setSortOrder(vCurrentSortOrder);

            const columns = gridControl.columns.columns;
            columns.filter(x => x.sort).forEach(col => {
                col.sortOrder = null;
                col.sort = null;
            });

            vCurrentSortOrder.forEach((ord, index) => {
                const vTmp = Object.keys(ord);
                const vCol = columns.find(x => x.field === vTmp[0]);
                if (vCol) {
                    if (vCurrentSortOrder.length > 1) {
                        vCol.sortOrder = index + 1;
                    }
                    vCol.sort = ord[vTmp[0]];
                }
            })

            dataObject.load();
            }
        }
    }